.SelectFilter {
  width: 100%;
}

.ParticipantPaymentColumnStyle {
  white-space: nowrap
}

.ChecklistDetails div  {
  flex-basis: 50%;
}

.ChecklistDetails div:first-child {
  text-align: center;
}

.ChecklistDetails div:last-child {
  text-align: right;
}

:global .participantMessage {
  background-color: #37353f;
}

.MessageTable {
  max-height: 655px;
  overflow-y: auto;
}

.NeedsAttentionToggle {
  margin-top: 32px;
}
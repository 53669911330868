.legend :global .ant-tag {
  width: 100px;
  text-align: center;
}

.legend :global .ant-space-item .ant-tag {
  font-weight: bold;
  color: #fff;
}

.legend :global .ant-space-item .ant-tag.tagLowest {
  color: #000;
}

.placement {
  & > div {
    position: relative;
    display: inline-block;
    padding: 10px;

    & > span {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
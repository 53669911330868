.KernelLogo {
  display: block;
  margin-right: 16px;
  height: 18px;
  cursor: pointer;
}

.LogoSeparator {
  height: 36px;
  width: 1px;
  background: white;
}

.Notification:hover{
  cursor: pointer;
}

.Notification {
  display: flex;
  flex-direction: column;
}

.Unread {
  background-color: #26242f;
  border-radius: 2px;
}

.Popover {
    height: 50vh;
    overflow: auto;
}
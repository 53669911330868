.FilteredSessionsContent {
  align-items: flex-start;
  width: 100%;
}

.FilteredSessionsContent {
  width: 100%;
}

.FilteredSessionsContent > *:last-child {
  width: 100%;
}

.FilteredSessionsFilters {
  width: 100%;
  min-width: 250px;
}

.FilteredSessionsSessions {
  width: 100%;
}

.FilteredSessionsSessions tr:hover {
  cursor: pointer;
}

.FilteredSessionsFilterLabel {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.Filter {
  width: 100%;
}

.SelectFilter {
  width: 100%;
}
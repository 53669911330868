.SessionTimelineTabPane {
  margin-top: 2rem;
  padding-left: 0.25rem;
}

.SessionDataStreamTransformed,
.SessionDataStreamFinalized {
  display: inline-flex;
  padding: 0.5rem;
  border-radius: 999999px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid transparent;
}

.SessionMetadataExperimentSelect {
  min-width: 200px;
}

.SessionPageHeaderInvalid {
  background: #d01421;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SessionPageHeaderInvalid h4 {
  margin-bottom: 0;
  color: white;
}

.SessionDataStreamsTable td {
  border-bottom: none !important;
}


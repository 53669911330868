.error {
  color: #c32121;
}
.error-message {
  color: #c32121;
  font-size: 14px;
  text-align: center;
  margin: 5px 0 15px 0;
}

.mantra {
  padding: 32px 36px;
  font-family: 'Museo';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.67px;
  text-align: center;
  color: #fff;
}

.subtext {
  width: 100%;
  font-size: 12px;
  margin: 6px 0 20px 0;
}

.carousel {
  width: 390px;
  height: 540px;
  padding: 28px 16px 76px 16px;
  border-radius: 6px;
  background-color: #26242f;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.carousel-input {
  width: 358px;
  height: 52px;
  border: solid 1px #000;
  padding-left: 12px;
  font-size: 18px;
}

.carousel-header {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  font-weight: bold;
}

.carousel-label {
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  margin: 4px 0 8px 0;
}

.carousel-checkbox-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0 0 26px 6px;
  cursor: pointer;
}
.carousel-checkbox-container label {
  margin-left: 14px;
}

.carousel-form {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.tab {
  width: 178px;
  padding: 10px 14px;
  margin: 4px 0 8px 0;
  font-family: inherit;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}
.tab.inactive {
  border: 1px solid #14131C;
  background-color: #fff;
  color: #14131C;
}
.tab.active {
  border: 1px solid #14131C;
  background-color: #14131C;
  color: #fff;
}
.tab-text {
  display: inline-block;
  vertical-align: middle;
}
.tab svg {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}
.tab span {
  vertical-align: middle;
}

.qr-code-container {
  text-align: left;
  margin: 10px 0;
  line-height: 16px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qr-code-container svg {
  margin-top: 32px;
}
.flow-layout-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.flow-layout-head {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.flow-layout-head :global .flow-head {
  fill: none;
  stroke: #fff;
}

.flow-layout-head :global .flow-labels {
  fill: #fff;
  stroke: #fff;
}

.flow-layout-inner :global .module-path, .flow-layout-inner :global .eeg-path {
  stroke: #fff;
}

.flow-layout-inner :global .module-text, .flow-layout-inner :global .eeg-text {
  font-family: 'Open Sans';
  stroke-width: 0;
  fill: #fff;
  text-anchor: middle;
}
.SurveyBuilderContent {
    width: 100%;
}

.SurveyBuilderContent > *:last-child {
    width: 100%;
}

.DraftButton,
.DraftButton:active,
.DraftButton:focus,
.DraftButton:hover {
    width: 161px;
    height: 48px;
}
.rowDragging {
  border: 1px solid #ccc;
  background-color: #121015;
}

.rowDragging .drag-visible {
  visibility: visible;
}

.rowDragging {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.rowDragging td:first-child {
  padding-left: 16px;
}

.rowDragging td:nth-child(2) {
  flex-basis: 50%;
}

.rowDragging td:nth-child(3) {
  flex-basis: 15%;
}
.rowDragging td:last-child {
  padding-right: 16px;
}
.OrganizationsEmpty,
.OrganizationsError,
.OrganizationsLoader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.OrganizationsEmpty {
  max-width: 700px;
  align-items: flex-start;
  text-align: left;
}

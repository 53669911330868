.SurveyBuilder {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #14131C;
    padding: 24px 20px;
    width: 299px;
}

.FormItem {
    display: block;
    margin-bottom: 12px;
}

.AddChoiceButton,
.AddChoiceButton:active,
.AddChoiceButton:focus,
.AddChoiceButton:hover {
    width: 75px;
    height: 32px;
    font-size: 12px;
    font-weight: bold;
}

.IneligbleAnswerRow {
    align-items: baseline;
}
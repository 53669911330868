.StudySurveyButton {
  margin-bottom: 0 !important;
}

.SurveyDisplayNameLabel {
  margin-right: 12px;
}

.CardTitleText {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
}
/* Study Card */
.studyCard {
  background: #26242f !important;
  padding: 10px;
  min-width: 200px;
  max-height: 300px;
  max-width: 400px;
}

.studyCardHoverable {
  border: 1px solid transparent;
  cursor: pointer;
}

.studyCardHoverable:hover {
  border: 1px solid white;
}

/* Cover Photo */
.coverPhoto {
  max-height: 175px;
  max-width: 370px;
  object-fit: contain;
}

/* Upload */
.uploadPhotoLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.Builder {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px
}

.ProtocolBuilderContent {
  width: 100%;
}

.ProtocolBuilderContent>*:last-child {
  width: 100%;
}

.ProtocolBuilder {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #14131C;
  padding: 24px 20px;
  width: 299px;
}

.FormItem {
  display: block;
  margin-bottom: 12px;
}
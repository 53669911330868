.ChecklistBuilderContent {
    width: 100%;
}

.ChecklistBuilderContent > *:last-child {
    width: 100%;
}

.ChecklistBuilder {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #14131C;
    padding: 24px 20px;
    width: 299px;
}

.FormItem {
    display: block;
    margin-bottom: 12px;
}
@font-face {
  font-family: 'Museo';
  font-weight: 500;
  src: url('./assets/fonts/museo/Museo500-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/opensans/OpenSans.ttf') format('opentype');
}

html, body { height: 100% }

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* AWS */
  --amplify-primary-color: #161e2e;

  /* scrollbar */
  --scrollbar-track-color: #26242f;
  --scrollbar-thumb-color: #dcdcdd;
}

/* scrollbar */

body::-webkit-scrollbar {
  background-color: var(--scrollbar-track-color);
  width: .75em;
}
 
body::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
  border-radius: 0.5rem;
}
 
body::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 0.5rem;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-color);
}
.ParticipantIdCell, .MessageCell {
  cursor: pointer;
}

.CirlceIndicator {
  width: 8px;
  height: 8px;
  margin-top: 6px;
  margin-right: 8px;
  background: #80BCFF;
  border-radius: 100%;
}

.FullWidth {
  width: 100%;
}